const data = {
  // if businessScore is 1, set partner as first item in array
  marketplaces: [
    {
      region_id: 1,
      region: 'na',
      marketplace_id: 'A2EUQ1WTGCTBG2',
      country_code: 'CA',
      name: 'Amazon.ca',
      countryCode: 'ca',
      flagCode: 'ca',
      country: 'Canada',
      isSelected: true,
      isConnected: false,
      vendorCentralUrl: 'https://vendorcentral.amazon.ca',
    },
    {
      region_id: 1,
      region: 'na',
      name: 'Amazon.com',
      marketplace_id: 'ATVPDKIKX0DER',
      country_code: 'US',
      countryCode: 'us',
      flagCode: 'us',
      country: 'United States of America',
      isSelected: true,
      isConnected: false,
      vendorCentralUrl: 'https://vendorcentral.amazon.com',
    },
    {
      region_id: 1,
      region: 'na',
      marketplace_id: 'A1AM78C64UM0Y8',
      name: 'Amazon.com.mx',
      country_code: 'MX',
      countryCode: 'mx',
      flagCode: 'com.mx',
      country: 'Mexico',
      isSelected: true,
      isConnected: false,
      vendorCentralUrl: 'https://vendorcentral.amazon.com.mx',
    },
    {
      region_id: 1,
      region: 'na',
      marketplace_id: 'A2Q3Y263D00KWC',
      country_code: 'BR',
      name: 'Amazon.com.br',
      countryCode: 'br',
      flagCode: 'com.br',
      country: 'Brazil',
      isSelected: true,
      isConnected: false,
      vendorCentralUrl: 'https://vendorcentral.amazon.com.br',
    },
    {
      region_id: 2,
      region: 'eu',
      marketplace_id: 'A1RKKUPIHCS9HS',
      country_code: 'ES',
      name: 'Amazon.es',
      countryCode: 'es',
      flagCode: 'es',
      country: 'Spain',
      isSelected: true,
      isConnected: false,
      vendorCentralUrl: 'https://vendorcentral.amazon.es',
    },
    {
      region_id: 2,
      region: 'eu',
      marketplace_id: 'A1F83G8C2ARO7P',
      country_code: 'UK',
      name: 'Amazon.co.uk',
      countryCode: 'uk',
      flagCode: 'uk',
      country: 'United Kingdom',
      isSelected: true,
      isConnected: false,
      vendorCentralUrl: 'https://vendorcentral.amazon.co.uk',
    },
    {
      region_id: 2,
      region: 'eu',
      marketplace_id: 'A13V1IB3VIYZZH',
      country_code: 'FR',
      name: 'Amazon.fr',
      countryCode: 'fr',
      flagCode: 'fr',
      country: 'France',
      isSelected: true,
      isConnected: false,
      vendorCentralUrl: 'https://vendorcentral.amazon.fr',
    },
    {
      region_id: 2,
      region: 'eu',
      marketplace_id: 'AMEN7PMS3EDWL',
      country_code: 'BE',
      name: 'Amazon.com.be',
      countryCode: 'be',
      flagCode: 'be',
      country: 'Belgium',
      isSelected: true,
      isConnected: false,
      vendorCentralUrl: 'https://vendorcentral.amazon.com.be',
    },
    {
      region_id: 2,
      region: 'eu',
      marketplace_id: 'A1805IZSGTT6HS',
      country_code: 'NL',
      name: 'Amazon.nl',
      countryCode: 'nl',
      flagCode: 'nl',
      country: 'Netherlands',
      isSelected: true,
      isConnected: false,
      vendorCentralUrl: 'https://vendorcentral.amazon.nl',
    },
    {
      region_id: 2,
      region: 'eu',
      marketplace_id: 'A1PA6795UKMFR9',
      country_code: 'DE',
      name: 'Amazon.de',
      countryCode: 'de',
      flagCode: 'de',
      country: 'Germany',
      isSelected: true,
      isConnected: false,
      vendorCentralUrl: 'https://vendorcentral.amazon.de',
    },
    {
      region_id: 2,
      region: 'eu',
      marketplace_id: 'APJ6JRA9NG5V4',
      country_code: 'IT',
      name: 'Amazon.it',
      countryCode: 'it',
      flagCode: 'it',
      country: 'Italy',
      isSelected: true,
      isConnected: false,
      vendorCentralUrl: 'https://vendorcentral.amazon.it',
    },
    {
      region_id: 2,
      region: 'eu',
      marketplace_id: 'A2NODRKZP88ZB9',
      country_code: 'SE',
      name: 'Amazon.se',
      countryCode: 'se',
      flagCode: 'se',
      country: 'Sweden',
      isSelected: true,
      isConnected: false,
      vendorCentralUrl: 'https://vendorcentral.amazon.se',
    },
    {
      region_id: 2,
      region: 'eu',
      marketplace_id: 'A1C3SOZRARQ6R3',
      country_code: 'PL',
      name: 'Amazon.pl',
      countryCode: 'pl',
      flagCode: 'pl',
      country: 'Poland',
      isSelected: true,
      isConnected: false,
      vendorCentralUrl: 'https://vendorcentral.amazon.pl',
    },
    /* {
       "region_id": 2,
       "region": "eu",
       "marketplace_id": "ARBP9OOSHTCHU",
       "country_code": "EG",
       "name": "Amazon.eg",
       "countryCode": "eq",
       "flagCode": "eg",
       "country": "Egypt",
       "isSelected": true
        isConnected: false,
     },*/
    {
      region_id: 2,
      region: 'eu',
      marketplace_id: 'A33AVAJ2PDY3EV',
      country_code: 'TR',
      name: 'Amazon.com.tr',
      countryCode: 'tr',
      flagCode: 'com.tr',
      country: 'Turkey',
      isSelected: true,
      isConnected: false,
      vendorCentralUrl: 'https://vendorcentral.amazon.com.tr',
    },
    {
      region_id: 2,
      region: 'eu',
      marketplace_id: 'A2VIGQ35RCS4UG',
      country_code: 'AE',
      domain_name: 'www.amazon.ae',
      name: 'Amazon.ae',
      countryCode: 'ae',
      flagCode: 'ae',
      country: 'United Arab Emirates',
      isSelected: true,
      isConnected: false,
      vendorCentralUrl: 'https://vendorcentral.amazon.me',
    },
    {
      region_id: 2,
      region: 'eu',
      marketplace_id: 'A21TJRUUN4KGV',
      country_code: 'IN',
      name: 'Amazon.in',
      countryCode: 'in',
      flagCode: 'in',
      country: 'India',
      isSelected: true,
      isConnected: false,
      vendorCentralUrl: 'https://vendorcentral.amazon.in',
    },
    {
      region_id: 2,
      region: 'eu',
      marketplace_id: 'A17E79C6D8DWNP',
      name: 'Amazon.sa',
      country_code: 'SA',
      countryCode: 'sa',
      flagCode: 'sa',
      country: 'Saudi Arabia',
      isSelected: true,
      isConnected: false,
      vendorCentralUrl: 'https://vendorcentral.amazon.me',
    },
    {
      region_id: 3,
      region: 'fe',
      marketplace_id: 'A19VAU5U5O7RUS',
      country_code: 'SG',
      name: 'Amazon.sg',
      countryCode: 'sg',
      flagCode: 'sg',
      country: 'Singapore',
      isSelected: true,
      isConnected: false,
      vendorCentralUrl: 'https://vendorcentral.amazon.sg',
    },
    {
      region_id: 3,
      region: 'fe',
      marketplace_id: 'A39IBJ37TRP1C6',
      country_code: 'AU',
      name: 'Amazon.com.au',
      countryCode: 'au',
      flagCode: 'com.au',
      country: 'Australia',
      isSelected: true,
      isConnected: false,
      vendorCentralUrl: 'https://vendorcentral.amazon.com.au',
    },
    {
      region_id: 3,
      region: 'fe',
      marketplace_id: 'A1VC38T7YXB528',
      name: 'Amazon.co.jp',
      country_code: 'JP',
      countryCode: 'jp',
      flagCode: 'co.jp',
      country: 'Japan',
      isSelected: true,
      isConnected: false,
      vendorCentralUrl: 'https://vendorcentral.amazon.co.jp',
    },
    {
      region_id: 2,
      region: 'eu',
      marketplace_id: 'A28R8C7NBKEWEA',
      country_code: 'IE',
      name: 'Amazon.ie',
      countryCode: 'ie',
      flagCode: 'ie',
      country: 'Ireland',
      isSelected: true,
      isConnected: false,
      vendorCentralUrl: 'https://vendorcentral.amazon.ie',
    },
  ],
};
export default data.marketplaces;

// US
// localhost:5000/selling_partner/authorize?spapi_oauth_code=ANFFDjgomjdMDQGxfHOk&state=link_vendor:na:A2EUQ1WTGCTBG2&selling_partner_id=amzn1.vg.728590

// CA
// localhost:5000/selling_partner/authorize?spapi_oauth_code=ANlPGsuUNFQDwdlPUGvH&state=link_vendor:na:ATVPDKIKX0DER&selling_partner_id=amzn1.vg.3071450
