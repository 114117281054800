const initialDownloadState = {
  taskId: null,
  status: 0,
  requestTime: null,
  downloadFileName: null,
  errorFileName: null,
  errorMessage: null,
};
const initialUploadState = {
  uplaodFile: {},
  userId: null,
  taskId: null,
  status: 0,
  requestTime: null,
  downloadFileName: null,
  errorFileName: null,
  errorMessage: null,
};

export default {
  getSelectedAccountTableRowData: (state) =>
    state.selectedAccountTableRowData || {},
  getSelectedContactTableRowData: (state) =>
    state.selectedContactTableRowData || {},
  getSelectedStoreTableRowData: (state) =>
    state.selectedStoreTableRowData || {},
  getSelectedOpportunityTableRowData: (state) =>
    state.selectedOpportunityTableRowData || {},
  getSelectedEventTableRowData: (state) =>
    state.selectedEventTableRowData || {},
  getSelectedTabs: (state) => state.selectedTabs,
  getSelectedAccountTabs: (state) => state.selectedAccountTabs,
  getOpportunityDatePicker: (state) => state.opportunityDatePicker,
  // getAccountList: (state) => state.tableLists.accountList,
  // getContactList: (state) => state.tableLists.contactList,
  // getStoreList: (state) => state.tableLists.storeList,
  // getRefAccountList: (state) => state.tableLists.refAccountList,
  // getInvoiceList: (state) => state.tableLists.invoiceList,
  // getActionList: (state) => state.tableLists.actionList,
  // getSubTabs: (state) => state.subTabs,
  getPaginationData: (state) => state.paginationData,
  getSearchKeyword: (state) => state.searchKeyword,
  getAccountListSelectedFilters: (state) => state.filters.accountList,
  getStoreListSelectedFilters: (state) => state.filters.storeList,
  getActionListSelectedFilters: (state) => state.filters.actionList,
  getInvoiceListSelectedFilters: (state) => state.filters.invoiceList,
  getOpportunityListSelectedFilters: (state) => state.filters.opportunityList,
  getOpportunityListSelectedSorting: (state) => state.sort.opportunityList,
  getEventListSelectedFilters: (state) => state.filters.eventList,

  // getAccountSubTabs: (state) => state.subTabs.accountSubTabs,
  // getContactSubTabs: (state) => state.subTabs.contactSubTabs,
  getAccountListInContactSubTabs: (state) => state.accountListInContactSubTabs,
  getSubSearchKeywords: (state) => state.subSearchKeywords,
  getSorting: (state) => state.sort,

  getAccountDetails: (state) => state.accountDetails,
  getAccountManagerList: (state) => state.accountManagerList,
  getContactTypes: (state) => state.contactTypes,
  getTimezoneList: (state) => state.timezoneList,
  getAccountSourceTypeList: (state) => {
    const noChoice = {
      type: '',
      displayName: 'No Select',
      details: null,
    };
    return [noChoice, ...state.accountSourceTypeMap];
  },
  getMarketingSourceTypeList: (state) => {
    return [
      {
        type: '-1',
        displayName: '',
      },
      {
        type: '1',
        displayName: 'Organic',
      },
      {
        type: '2',
        displayName: 'Direct',
      },
      {
        type: '3',
        displayName: 'Referral',
      },
      {
        type: '4',
        displayName: 'Paid',
      },
      {
        type: '5',
        displayName: 'Email Marketing',
      },
      {
        type: '6',
        displayName: 'Other',
      },
    ];
  },
  getSalesAccountManagerList: (state) => {
    const salesAccountManagerList = state.accountManagerList.filter(
      (item) => item.managementType === 0
    );
    const noChoice = {
      email: '',
      managementType: 0,
      managementTypeText: 'Sales Account Manager',
      managerName: 'No Select',
      managerId: '',
    };
    return [noChoice, ...salesAccountManagerList];
  },

  getSalesDevelopmentRepresentativeList: (state) => {
    const salesDevelopmentRepresentativeList = state.accountManagerList.filter(
      (item) => item.managementType === 3
    );
    const noChoice = {
      email: '',
      managementType: 3,
      managementTypeText: 'Sales Development Representative',
      managerName: 'No Select',
      managerId: '',
    };
    return [noChoice, ...salesDevelopmentRepresentativeList];
  },

  getCustomerSuccessManagerOptionsList: (state) => {
    const customerSuccessManagerList = state.accountManagerList.filter(
      (item) => item.managementType === 4
    );
    const noChoice = {
      email: '',
      managementType: 4,
      managementTypeText: 'Customer Success Manager',
      managerName: 'No Select',
      managerId: '',
    };
    return [noChoice, ...customerSuccessManagerList];
  },

  getIsAddNewAccount: (state) => state.isAddNewAccount,
  getIsAddNewContact: (state) => state.isAddNewContact,
  getIsAddNewOpportunity: (state) => state.isAddNewOpportunity,
  getIsAddNewEvent: (state) => state.isAddNewEvent,
  //export
  download: ({ download }, _, __, rootGetters) => {
    const evaStoreId = rootGetters.getEvaStoreId;
    return download[evaStoreId] ?? initialDownloadState;
  },
  upload: ({ upload }, _, __, rootGetters) => {
    const evaStoreId = rootGetters.getEvaStoreId;
    return upload[evaStoreId] ?? initialUploadState;
  },
  getIsSuccessUpload: (state) => state.isSuccessUpload,
  getShowAccountDetailButton: (state) => state.showAccountDetailButton,
  getFetchActionDetailList: (state) => state.fetchActionDetailList,
  getFetchOpportunityDetailList: (state) => state.fetchOpportunityDetailList,
};
